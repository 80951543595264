<template>
    <div class="home">
        <div class="header">
            <div class="logo" @click="gopath">
                <img src="../assets/logo.png" alt="">

            </div>
            <div class="nav" @click="gopath">
                <div class="title">{{$t('home')}}</div>
            </div>
            <div class="changelang" @click="changelang">
                <div class="langicon">
                    <img src="../assets/icon3.png" />
                </div>
                <div>{{$t('switch')}}</div>
            </div>
        </div>
        <div class="swiperbox">
            <el-carousel :height="bannerHeight+'px'"  :interval="4000">
                <el-carousel-item v-for="(item,index) in swiperlist" :key="index">
                    <img :src="item.img" ref="bannerHeight" @load="imgLoad" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            bannerHeight: 300,
            swiperlist: [
                {
                    img: require("../assets/swiper.png"),
                },
                {
                    img: require("../assets/swiper1.png"),
                },
                {
                    img: require("../assets/swiper2.png"),
                },
            ],
        };
    },
    methods: {
        changelang() {
            if (sessionStorage.getItem("lang") == "zh") {
                this.$i18n.locale = "en";
                sessionStorage.setItem("lang", "en");
            } else {
                this.$i18n.locale = "zh";
                sessionStorage.setItem("lang", "zh");
            }
        },
        gopath() {
            this.$router.push({ path: "/index" });
        },
        imgLoad() {
            this.$nextTick(() => {
                this.bannerHeight = this.$refs.bannerHeight[0].height;
            });
        },
    },
    mounted() {
        this.imgLoad();
        window.addEventListener(
            "resize",
            () => {
                this.bannerHeight = this.$refs.bannerHeight[0].height;
                this.imgLoad();
            },
            false
        );
    },
};
</script>
<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
}
.header {
    display: flex;
    padding: 10px 20px 0;
    position: relative;
    .logo {
        width: 120px;
        height: 120px;
        // transform: rotate(45deg);
        position: absolute;
        top: 0px;
        left: 80px;
        z-index: 9;
         img {
                width: 120px;
                height: 120px;
            }
    }
    .nav {
        width: 118px;
        height: 64px;
        background: linear-gradient(
            41deg,
            #c9d9ee 0%,
            #a2b9e6 58%,
            #86a2e1 100%
        );
        font-weight: bold;
        line-height: 64px;
        font-size: 18px;
        color: #ffffff;
        border-radius: 10px 20px 0 0;
        text-align: center;
        margin-left: 230px;
        transform: skewX(-20deg);
        .title {
            transform: skewX(20deg);
        }
    }
    .changelang {
        position: absolute;
        right: 100px;
        top: 20px;
        display: flex;
        font-size: 18px;
        color: #05123d;
        cursor: pointer;
        .langicon {
            width: 22px;
            margin-right: 10px;
            img {
                width: 100%;
            }
        }
    }
}
.swiperbox {
    width: 100%;
    overflow: hidden;
    /deep/.el-carousel__arrow {
            background-color: #fff;
            color: #000;
            font-size: 14px;
        }
    img {
        width: 100%;
    }
}
</style>
