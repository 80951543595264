export default{
    switch:'Switch to Chinese',
    home:'Home',
    service:'service concept',
    serviceText:'We adhere to the customer-centric, providing personalized and customized service solutions. By continuously optimizing our service process and improving service quality, we strive to create maximum value for our customers in the fierce market competition.',
    team:'Team & Strengths',
    teamText:'Shenzhen Shangcheng Huazhi Import and Export Co., Ltd. has an experienced and professional team. Our team members have extensive knowledge and practical experience in international trade, logistics, law and marketing. Our strength lies in our ability to respond quickly to market changes, flexibly adjust strategies, and provide innovative solutions.',
    future:'Future outlook',
    futureText:'With the deepening of globalization, Shenzhen Shangcheng Huazhi Import and Export Co., Ltd. will continue to expand our business scope and market influence. We look forward to working with more domestic and foreign partners to jointly open up a broader international market.',
    onlineText:'All images on this website are from the Internet',
    info:' is a professional import and export trading company, founded in 2023, headquartered in Shenzhen, China, near the Hong Kong Special Administrative Region. We have established long-term and stable cooperative relationships with a number of quality suppliers in China, and through efficient supply chain management and professional market analysis, we ensure the smooth flow of goods and efficient execution of transactions.',
    phone:'Contact information',
    people:'Mr. Chen',
    e_mail:'Email',
    address:'Company address',
    addressinfo:"2301, Building 3, Six Gardens, CITIC Bay, Labor Community, Xixiang Street, Bao'an District, Shenzhen",
    cooperation:'For cooperation, please contact us through the above methods！',
}