export default{
    switch:'切换到英文',
    home:'首页',
    service:'服务理念',
    serviceText:'我们坚持以客户为中心，提供个性化和定制化的服务方案。通过不断优化我们的服务流程和提高服务质量，我们力求在激烈的市场竞争中为客户创造最大的价值。',
    team:'团队与优势',
    teamText:'深圳市尚诚华志进出口有限公司拥有一支经验丰富、专业能力强的团队。我们的团队成员具备国际贸易、物流、法律和市场营销等多方面的专业知识和实践经验。我们的优势在于能够快速响应市场变化，灵活调整策略，以及提供创新的解决方案。',
    future:'未来展望',
    futureText:'随着全球化的不断深入，深圳市尚诚华志进出口有限公司将继续扩大我们的业务范围和市场影响力。我们期待与更多国内外合作伙伴携手合作，共同开拓更广阔的国际市场。',
    onlineText:'本网站所有图片来源于网络',
    info:'是一家专业的进出口贸易公司，成立于2023年，总部位于中国深圳市，临近香港特别行政区。我们与中国多个优质供应商建立了长期稳固的合作关系，通过高效的供应链管理和专业的市场分析，确保货物的顺利流通和交易的高效执行。',
    phone:'联系方式',
    people:'陈先生',
    e_mail:'电子邮箱',
    address:'公司地址',
    addressinfo:"深圳市宝安区西乡街道劳动社区中信湾上六座花园3栋2301",

    cooperation:'如需合作，请通过以上方式联系我们！',



}