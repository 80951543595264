<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
    created () {
        if(sessionStorage.getItem('lang')){
            this.$i18n.locale = sessionStorage.getItem('lang')
        }else{
            this.$i18n.locale = "en"
            sessionStorage.setItem('lang',"en")
        }
    },
}
</script>
<style lang="scss">
#app{
  min-width: 1520px !important;

}
 body{
  margin: 0;
 }
</style>
