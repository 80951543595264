import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [{
      path: '/index',
      name: 'index',
      component: () => import('../views/index.vue'),
      meta: { title: '首页' },
    },{
      path: '/info',
      name: 'info',
      component: () => import('../views/info.vue'),
      meta: { title: '服务理念' },
    }]
  }
]


const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
